import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StructuredText } from 'datocms-structured-text-utils';
import TrustPilotWidget from '@ui/components/TrustPilotWidget/TrustPilotWidget';
import StructuredTextWrapper from '@ui-v2/components/RichContent/StructuredText';
import Box from '@ui-v2/core/Box/Box';
import Image from '@ui-v2/core/Image/Image';
import Modal, { ModalProps } from '@ui-v2/core/Modal/Modal';
import OneButtonModalFooter from '@ui-v2/core/Modal/OneButtonModalFooter';
import Text from '@ui-v2/core/Text/Text';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import useGetIllustration from '@utils/hooks/useGetIllustration';
import { useConstants } from '@web/context/ConstantContext';
import useDohopConnect from '@web/context/hooks/useDohopConnect';
import { useSettings } from '@web/context/SettingsContext';

type Props = {
  isOpen: boolean;
  onOpenChange: ModalProps['onOpenChange'];
};

const StyledContentWrapper = styled.div(
  ({ theme }) => css`
    strong {
      display: inline-block;
      margin-bottom: ${theme.spacings[4]}px;
    }
  `,
);

const DohopConnectRichInformationModal = ({ isOpen, onOpenChange }: Props) => {
  const { locale } = useConstants();
  const { residency } = useSettings();
  const { t } = useCmsTranslation();
  const dohopConnectIllustration = useGetIllustration()(
    'dohopConnectIllustration',
  );

  const { richModalBulletpoints, richModalTitle } = useDohopConnect();

  return (
    <Modal
      footer={
        <OneButtonModalFooter
          onOpenChange={onOpenChange}
          text={t('Close', 'Close')}
        />
      }
      id="dohop-connect-rich-information-modal"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title={richModalTitle}
    >
      <Box
        display="flex"
        flexWrap={['wrap', 'wrap', 'nowrap']}
        gap={32}
        pb={24}
        pl={24}
        pr={[0, 0, 24]}
        pt={16}
      >
        <Box
          display="flex"
          flexShrink={0}
          mx={['auto', 'auto', 0]}
          widthCustom={120}
        >
          {dohopConnectIllustration?.asset.url && (
            <Image
              height={105}
              src={dohopConnectIllustration.asset.url}
              width={120}
            />
          )}
        </Box>
        <Box display="flex" flexWrap={['wrap', 'wrap', 'nowrap']} gap={32}>
          {richModalBulletpoints.map((bullet) => (
            <Box
              key={bullet.id}
              position="relative"
              width={['full', 'full', '6/12']}
            >
              <Text as="div">
                <Box
                  display={['block', 'block', 'none', 'block']}
                  float={['none', 'none', 'left']}
                  left={[-28, -28, -8]}
                  position={['absolute', 'absolute', 'relative']}
                  top={2}
                />
                <StyledContentWrapper>
                  <StructuredTextWrapper
                    data={bullet.content as StructuredText | null | undefined}
                  />
                </StyledContentWrapper>
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
      <Box display="flex" gap={[0, 0, 32]} pb={8} px={24}>
        <Box widthCustom={[0, 0, 120]}>
          {/* Faux icon box to align content */}
        </Box>
        <Box>
          <Text as="h3" mb={8} variant="heading-5">
            {t(
              'dohop_information_modal_trustpilot_title',
              'Check what our Dohop customers are saying on',
            )}
          </Text>
          <Box height="100px" widthCustom={162}>
            <TrustPilotWidget
              height="100px"
              localeString={`${locale}-${residency}`}
              widgetType="mini"
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DohopConnectRichInformationModal;
